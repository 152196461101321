import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-header border-0 py-5" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = {
  type: "button",
  class: "btn btn-sm btn-icon btn-color-primary btn-active-light-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_4 = { class: "svg-icon svg-icon-2" }
const _hoisted_5 = { class: "card-body d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title align-items-start flex-column" }, [
        _createElementVNode("span", { class: "card-label fw-bolder fs-3 mb-1" }, "Trends"),
        _createElementVNode("span", { class: "text-muted fw-bold fs-7" }, "Latest trends")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ]),
        _createVNode(_component_Dropdown3)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_apexchart, {
        class: "mixed-widget-5-chart card-rounded-top",
        options: _ctx.chartOptions,
        series: _ctx.series,
        type: "area",
        height: _ctx.chartHeight
      }, null, 8, ["options", "series", "height"]),
      _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"mt-5\"><div class=\"d-flex flex-stack mb-5\"><div class=\"d-flex align-items-center me-2\"><div class=\"symbol symbol-50px me-3\"><div class=\"symbol-label bg-light\"><img src=\"media/svg/brand-logos/plurk.svg\" alt=\"\" class=\"h-50\"></div></div><div><a href=\"#\" class=\"fs-6 text-gray-800 text-hover-primary fw-bolder\">Top Authors</a><div class=\"fs-7 text-muted fw-bold mt-1\"> Ricky Hunt, Sandra Trepp </div></div></div><div class=\"badge badge-light fw-bold py-4 px-3\">+82$</div></div><div class=\"d-flex flex-stack mb-5\"><div class=\"d-flex align-items-center me-2\"><div class=\"symbol symbol-50px me-3\"><div class=\"symbol-label bg-light\"><img src=\"media/svg/brand-logos/figma-1.svg\" alt=\"\" class=\"h-50\"></div></div><div><a href=\"#\" class=\"fs-6 text-gray-800 text-hover-primary fw-bolder\">Top Sales</a><div class=\"fs-7 text-muted fw-bold mt-1\">PitStop Emails</div></div></div><div class=\"badge badge-light fw-bold py-4 px-3\">+82$</div></div><div class=\"d-flex flex-stack\"><div class=\"d-flex align-items-center me-2\"><div class=\"symbol symbol-50px me-3\"><div class=\"symbol-label bg-light\"><img src=\"media/svg/brand-logos/vimeo.svg\" alt=\"\" class=\"h-50\"></div></div><div class=\"py-1\"><a href=\"#\" class=\"fs-6 text-gray-800 text-hover-primary fw-bolder\">Top Engagement</a><div class=\"fs-7 text-muted fw-bold mt-1\">KT.com</div></div></div><div class=\"badge badge-light fw-bold py-4 px-3\">+82$</div></div></div>", 1))
    ])
  ], 2))
}