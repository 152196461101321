import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-xxl-stretch" }
const _hoisted_2 = { class: "card-toolbar" }
const _hoisted_3 = { class: "svg-icon svg-icon-2" }
const _hoisted_4 = { class: "card-body p-0" }
const _hoisted_5 = { class: "card-p mt-n20 position-relative" }
const _hoisted_6 = { class: "row m-0" }
const _hoisted_7 = { class: "col bg-light-warning px-6 py-8 rounded-2 me-7 mb-7" }
const _hoisted_8 = { class: "svg-icon svg-icon-3x svg-icon-warning d-block my-2" }
const _hoisted_9 = { class: "col bg-light-primary px-6 py-8 rounded-2 mb-7" }
const _hoisted_10 = { class: "svg-icon svg-icon-3x svg-icon-primary d-block my-2" }
const _hoisted_11 = { class: "row m-0" }
const _hoisted_12 = { class: "col bg-light-danger px-6 py-8 rounded-2 me-7" }
const _hoisted_13 = { class: "svg-icon svg-icon-3x svg-icon-danger d-block my-2" }
const _hoisted_14 = { class: "col bg-light-success px-6 py-8 rounded-2" }
const _hoisted_15 = { class: "svg-icon svg-icon-3x svg-icon-success d-block my-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown3 = _resolveComponent("Dropdown3")!
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([`bg-${_ctx.widgetColor}`, "card-header border-0 py-5"])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "card-title fw-bolder text-white" }, "Sales Statistics", -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          type: "button",
          class: _normalizeClass([`btn-active-color-${_ctx.widgetColor}`, "btn btn-sm btn-icon btn-color-white btn-active-white border-0 me-n3"]),
          "data-kt-menu-trigger": "click",
          "data-kt-menu-placement": "bottom-end",
          "data-kt-menu-flip": "top-end"
        }, [
          _createElementVNode("span", _hoisted_3, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen024.svg" })
          ])
        ], 2),
        _createVNode(_component_Dropdown3)
      ])
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_apexchart, {
        class: _normalizeClass([`bg-${_ctx.widgetColor}`, "mixed-widget-2-chart card-rounded-bottom"]),
        options: _ctx.chartOptions,
        series: _ctx.series,
        height: "200",
        type: "area"
      }, null, 8, ["class", "options", "series"]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen032.svg" })
            ]),
            _cache[1] || (_cache[1] = _createElementVNode("a", {
              href: "#",
              class: "text-warning fw-bold fs-6"
            }, " Weekly Sales ", -1))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr075.svg" })
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("a", {
              href: "#",
              class: "text-primary fw-bold fs-6"
            }, " New Users ", -1))
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", _hoisted_13, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs027.svg" })
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("a", {
              href: "#",
              class: "text-danger fw-bold fs-6 mt-2"
            }, " Item Orders ", -1))
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", _hoisted_15, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/communication/com010.svg" })
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("a", {
              href: "#",
              class: "text-success fw-bold fs-6 mt-2"
            }, " Bug Reports ", -1))
          ])
        ])
      ])
    ])
  ]))
}