import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body p-0 d-flex justify-content-between flex-column overflow-hidden" }
const _hoisted_2 = { class: "d-flex flex-stack flex-wrap flex-grow-1 px-9 pt-9 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "me-2" }, [
          _createElementVNode("span", { class: "fw-bolder text-gray-800 d-block fs-3" }, "Sales"),
          _createElementVNode("span", { class: "text-gray-400 fw-bold" }, "Oct 8 - Oct 26 21")
        ], -1)),
        _createElementVNode("div", {
          class: _normalizeClass(["fw-bolder fs-3", `text-${_ctx.chartColor}`])
        }, "$15,300", 2)
      ]),
      _createVNode(_component_apexchart, {
        class: "mixed-widget-10-chart",
        options: _ctx.chartOptions,
        series: _ctx.series,
        type: "bar",
        height: _ctx.chartHeight
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}