import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-body d-flex flex-column p-0" }
const _hoisted_2 = { class: "flex-grow-1 card-p pb-0" }
const _hoisted_3 = { class: "d-flex flex-stack flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.widgetClasses, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "me-2" }, [
            _createElementVNode("a", {
              href: "#",
              class: "text-dark text-hover-primary fw-bolder fs-3"
            }, "Generate Reports"),
            _createElementVNode("div", { class: "text-muted fs-7 fw-bold" }, " Finance and accounting reports ")
          ], -1)),
          _createElementVNode("div", {
            class: _normalizeClass([`text-${_ctx.chartColor}`, "fw-bolder fs-3"])
          }, " $24,500 ", 2)
        ])
      ]),
      _createVNode(_component_apexchart, {
        class: "mixed-widget-7-chart card-rounded-bottom",
        options: _ctx.chartOptions,
        series: _ctx.series,
        type: "area",
        height: _ctx.chartHeight
      }, null, 8, ["options", "series", "height"])
    ])
  ], 2))
}